import React  from "react"
import { useGetEPTP } from "../../../pages/upcomingEventsEmailList"
import moment from "moment"
import getSlug from "../../../functions/getSlug"
import { DollarAmount } from "../../../components/reusable/DollarAmount"

// create a link to a productDetailed page (and include the date in the caption)
// note: the "eventDate" prop is a custom obj, not a graphQl object
export const ProductDateAHref = ({ eventDate, ...remainingProps }) => {
  const { productType } = eventDate
  
  // const isoDateTimeString = '2023-08-01T18:00:00+10:00'; // Replace with your ISO DateTime string

  // Parse the ISO string using moment
  const parsedDateTime = moment(eventDate.startDateTime)
  // Format the DateTime using the desired format
  const formattedDateTime = parsedDateTime.format('D MMM')
  
  const cancelledLabel = (eventDate.cancelled) ? (<b>This is Cancelled!: </b>) : ''
  const captionPrefix = cancelledLabel + " [" + formattedDateTime + "] "

  return (
    <ProductAHref 
      captionPrefix={captionPrefix}
      productType={productType}
      includePricing={true}
      // utm_source={utm_source} utm_medium={utm_medium} utm_campaign="Pr0552"
      {...remainingProps}
    />
  )
}

export const ProductAHref = ({ captionPrefix, includePricing = true, productType, utm_source, utm_medium, utm_campaign }) => {
    const eptp = useGetEPTP()
    const name = productType.name
  
    let url = "http://www.NewToMelbourne.org/" + getSlug(productType.urlSlug)
    url = updateQueryStringParameter(url, 'scd', eptp)
  
    // set google analytics params:
    url = updateQueryStringParameter(url, 'utm_source', utm_source)
    url = updateQueryStringParameter(url, 'utm_medium', utm_medium)
    url = updateQueryStringParameter(url, 'utm_campaign', utm_campaign)

    const PricingIncluded = () => {
      if (!includePricing) { return (<></>) }

      return (
        <Pricing priceInCents={productType.sellPrice} />
      )
    }
    
    return (
      <>
        <a href={url}>
          {captionPrefix} {name} <PricingIncluded />
        </a>
      </>
    )
}

// display the pricing of a product (with price inflator and discounts applied.)
const Pricing = ({ priceInCents }) => {
  if (priceInCents == 0) { 
    return (<>(FREE)</>)
  }

  const sellPrice = <DollarAmount cents={priceInCents} />
  
  return (
    <>
      (from: {sellPrice})
    </>
  )
}

// set a value in the url query params, then return the new url as a string
function updateQueryStringParameter(url, key, value) {
    const urlObject = new URL(url)
    const searchParams = urlObject.searchParams

    // Update or add the query parameter
    searchParams.set(key, value)

    // Get the updated URL string
    const updatedUrl = urlObject.toString();

    return updatedUrl;
}