import React, { useState }  from "react";

import Layout from "../components/Layout";
// import { useMetaDataQuery } from "../../hooks/useMetaDataQuery";
import { useUpcomingEvents } from "../hooks/useUpcomingEvents";
import { useBuildDates } from "../hooks/useBuildDates";
import queryString from 'query-string'
import { useLocation } from "@gatsbyjs/reach-router";
import { useGetSpecialTours } from "../hooks/useGetSpecialTours";
import { ProductAHref, ProductDateAHref } from "../_shared/components/ProductAHref";
import { useGetHighlightsProductList } from "../hooks/useGetHightlightsProductsList";
import { specialsExplainedCaption } from "../_shared/ProductList/ByDate";

// get the URL query param "eptp" (Aka Email PassThrough Parameter). This will be used as the SCD parameter in the email links.
export const useGetEPTP = () => {
  const { search } = useLocation()
  let eptp = queryString.parse(search)['eptp']
  console.log('eptp [erf]: ', eptp)

  return eptp
}

// Warn the user if EPTP is not set (and prevent display of event dates).
const CheckEPTP = ({ children }) => {
  const eptp = useGetEPTP()

  if (!eptp) {
    return (
      <div>
        <b>Warning: EPTP query param is not set.</b> Please set this before continuing.
      </div>
    ) 
  }

  return (
    <>
      {children}
    </>
  )
}

const EventsList = () => {
  const [ isNewInverted, setIsNewInverted ] = useState(0) 

  // get the url query param "eptp" (Email PassThrough Parameter). This will be used as the SCD parameter in the email links.
  const { search } = useLocation()
  let eptp = queryString.parse(search)['eptp']
  console.log('eptp [erf]: ', eptp)
  
  const dates = useUpcomingEvents()
  const highlights = useGetHighlightsProductList(true)
  console.log("highlights [ji8]: ", highlights)
  console.log('dates [sd5]: ', dates)
  const allDates = useBuildDates(dates)
  console.log('useUpcomingEvents [xc3]: ', allDates)

  if (!allDates) {
    return (
      <>No upcoming dates or trips.</>
    )
  }
  
  const output1 = <EventDateList allDates={allDates} isNewInverted={isNewInverted} />

  const todoStyle = {
    color: "red"
  }

  const invertNewOption = () => {
    setIsNewInverted (1 - isNewInverted )
    // alert(isNewInverted)
  }
  
  return (
    <>
      (for instructions on this page. Please see: NTM / OTA > SBM-040)

      <br />< br />(note: eptp is currently set to: <u>{(eptp)?  eptp : "not set!" }</u>)
      <br /><b>Standard eptp options:</b>
      <ul>
        <li><a href="/upcomingEventsEmailList/?eptp=*|SCD|*.mcec">*|SCD|*.mcec: Mailchimp email campaign (it uses the merge / personalisation tag)</a></li>
        <li><a href="/upcomingEventsEmailList/?eptp=megec">megec: Melbourne Explorers Group email campaign</a></li>
        <li><a href="/upcomingEventsEmailList/?eptp=ffec">ffec: Fluent Fast Group email campaign</a></li>
      </ul>
      <CheckEPTP>
        <b>Special tours:</b>
        <br />{specialsExplainedCaption}
        <SpecialsList specials={highlights} />
        
        <b>Normal schedule:</b> (<a href="#" onClick={() => invertNewOption()}>Invert NEW! option</a>) 
        <br />Our (current) upcoming events and sightseeing tours:
        <ul>
          {/* <li>[ <span style={todoStyle}><b>TODO</b> #1: remove events more than 45 days out.</span> ]</li>
          <li>[ <span style={todoStyle}><b>TODO</b> #2: "announce" / mark the new events with <b><u>NEW!</u></b></span> ]</li> */}
          {output1}
        </ul>
      </CheckEPTP>
    </>
  )
}

// display a UL of products with no upcoming dates or marked as "special"
// todo: change this to a "highlights" product list (to get better control over what is displayed)
const SpecialsList = ({ specials }) => {
  const result = specials.map((curProduct) => {
    console.log("curProduct #[kb9]: ", curProduct)
    
    return (
      <li>
        <ProductAHref
          productType={curProduct}
          utm_source="emailnewsletter" utm_medium="email" utm_campaign="Pr0552"
        />
      </li>
    )
  })

  return (
    <ul>
      {result}
    </ul>
  )
}

// Render down each eventDates into a <li> and <a>
const EventDateList = ({ allDates, isNewInverted }) => {
    console.log('allDates: asd123', allDates)
    
    return allDates.map((curItem) => {
      const curDate = curItem[1]
      const { productType } = curDate
      console.log('curDate [dk79]:', curDate)
      if (!curDate) {
        // the CMS editor did not assign a product - so skip it.
        return productType.name
        // return ('product empty')
      }


      return (
          <li>
            <IsNew isNewInverted={isNewInverted} />
              <ProductDateAHref
                eventDate={curDate}
                utm_source="emailnewsletter" utm_medium="email" utm_campaign="Pr0552"
              /> 
          </li>
      )
    })
}

// shows a NEW! that when clicked, disappears.
const IsNew = ( { isNewInverted }) => {
  const [ isNew, setIsNew ] = useState(1)

  if (isNew == isNewInverted) {
    return (
      <></>
    )
  }
  
  const newLinkStyle = {
    "font-weight": 'bold',
    "text-decoration": 'underline',
    "cursor": 'pointer' 
  }

  return (
    <>
      {/* <b><u>NEW!</u></b> */}
      <span onClick={() => setIsNew(1 - isNew)} style={newLinkStyle}>NEW!</span>
    </>
  )
}

// markup
const IndexPage = () => {
  return (
    <Layout>
      <EventsList />
    </Layout>
  )
};

export default IndexPage;